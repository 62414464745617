import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Community Design Library`}</h2>
    <p>{`We have also released a design library to the figma community, which can be used to create a digital product design with foundation templates and UI Kit components that you can use directly or customize as needed. You can access the figma community page `}<inlineCode parentName="p">{`@telkomdesign`}</inlineCode>{`.`}</p>
    <div {...{
      "className": "community__design-banner"
    }}>{`
  `}<div parentName="div" {...{
        "className": "banner-detail"
      }}>{`
    `}<img parentName="div" {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/get-started/telkom-design-logo.svg",
          "alt": "telkom design logo"
        }}></img>{`
    `}<div parentName="div">{`
      `}<h6 parentName="div">{`Telkom Design`}</h6>{`
      `}<p parentName="div">{`@TelkomDesign`}</p>{`
    `}</div>{`
  `}</div>{`
  `}<div parentName="div" {...{
        "className": "banner-link-container"
      }}>{`
    `}<a parentName="div" {...{
          "className": "banner-link",
          "href": "https://www.figma.com/@telkomdesign",
          "target": "_blank",
          "rel": "noopener noreferrer"
        }}>{`
      Go to Figma Community
      `}<img parentName="a" {...{
            "src": "/images/external-link-purple.svg",
            "alt": "external link icon"
          }}></img>{`
    `}</a>{`
  `}</div>
    </div>
    <br />
    <br />
    <h2>{`Usage Library`}</h2>
    <p>{`Legion community UI Kit provides you with all the components that Legion design system use. Component variants give you the same flexibility and component configurations.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Open our community profile `}<br />{` `}<br /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select the file you need, or if from scratch you can select foundation first `}<br />{` `}<br /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Click “Get a copy” button to duplicate file your selected to draft `}<br />{` `}<br /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Change the foundation properties with your product style and publish as a library `}<br />{` `}<br /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Select and “Get a copy” the component file `}<br />{` `}<br /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Connected your foundation before to the component from .base component on each page `}<br />{` `}<br /></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Publish component as library and you can use both of library to craft or styling your design screen `}<br />{` `}<br /></p>
      </li>
    </ol>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/design/get-started/usage-lib-external.svg",
      "alt": "usage library external"
    }}></img>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      